import React from "react";
import SiteLayout from "../components/SiteLayout";

import Breadcrumb from "../components/BreadCrumb/";
import Title from "../components/Title/";
import Block from "../components/Block/";
import ThankYou from "../components/ThankYou";

export default class ThankYouCareerPage extends React.Component {
    render() {
        return (
            <SiteLayout location={this.props.location}>
                <Breadcrumb
                    crumbs={[{ label: "Děkujeme", link: "thank-you-career" }]}
                />
                <Block>
                    <Title type={"h1"} color={"gold"} mb={40}>
                        Díky za váš zájem!
                    </Title>
                </Block>
                <Block radialGradient={true}>
                    <ThankYou
                        typeLabel="V blízké době vás bude kontaktovat s detailní nabídkou vhodnou pro vás."
                        typeLink="/kariera-poradce-pro-investicni-kovy/"
                    />
                </Block>
            </SiteLayout>
        );
    }
}
